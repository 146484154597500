<template>
  <div class='page-box'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="申请时间" prop ='dataRange'>
          <a-range-picker v-model="params.dataRange" />
          <a-button type='link' style='padding-right: 0' @click='setNearWeek'>近一周</a-button>
          <a-button type='link' style='padding-right: 0' @click='setNearMonth'>近一月</a-button>
        </a-form-model-item>
        <!-- <a-form-model-item label="提现状态" prop='state'>
          <a-select
            v-model="params.state"
            placeholder="请选择"
            style="min-width: 120px"
          >
            <a-select-option :value='0'>待审核</a-select-option>
            <a-select-option :value="1">提现成功</a-select-option>
            <a-select-option :value="-1">审核拒绝</a-select-option>
            <a-select-option :value="-2">提现失败</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <a-table
      bordered
      :pagination="pagination"
      :columns="columns"
      :dataSource="dataRef"
      :rowKey="record => record.shopCashApplyId"
      :loading="loadingRef"
      @change="handleTableChange">
      <template slot="nickName" slot-scope="text, record">
       {{record.realName||record.nickName}}
      </template>
      <template slot="payChannel" slot-scope="text, record">
        <div>
          {{record.payChannel}}
        </div>
        <div>
          {{record.payOrderNo}}
        </div>
        <div>
          {{record.paySerialNo}}
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { capital } from '@/api'
import moment from 'moment'
export default {
  name: 'BalanceRecord',
  setup (props, { root }) {
    const columns = [
      {
        title: '申请时间',
        dataIndex: 'createTime'
      },
      {
        title: '结算金额',
        dataIndex: 'cashAmount',
        customRender: x => {
          return x.toFixed(2) + '元' || '0元'
        }
      },
      {
        title: '到账银行卡',
        dataIndex: 'bankCardNo',
        scopedSlots: { customRender: 'bankCardNo' }
      },
      {
        title: '状态',
        dataIndex: 'state',
        customRender: x => {
          if (x === 0) return '待审核'
          if (x === 1) return '提现成功'
          if (x === -1) return '审核拒绝'
          if (x === -2) return '提现失败'
        }
      },
      {
        title: '备注',
        dataIndex: 'remark'
      },
      {
        title: '操作人',
        scopedSlots: { customRender: 'nickName' }
      },
      {
        title: '支付渠道｜订单号｜流水号',
        scopedSlots: { customRender: 'payChannel' }
      }
    ]
    const state = reactive({
      params: {
        dataRange: []
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      loadingRef: false,
      formRef: null,
      dataRef: []
    })
    onMounted(() => {
      getTableList()
    })
    async function getTableList () {
      state.loadingRef = true
      let { code, msg, data, page } = await capital.getBalanceRecordPage({
        shopId: getSession(SHOPID),
        startDate: state.params.dataRange.length ? moment(state.params.dataRange[0]).format('YYYY-MM-DD') : '',
        endDate: state.params.dataRange.length ? moment(state.params.dataRange[1]).format('YYYY-MM-DD') : '',
        state: state.params.state,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.loadingRef = false
      if (code === '00000') {
        state.dataRef = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }

    function handleTableChange (page) {
      state.pagination = page
      getTableList()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableList()
    }

    function handleReset () {
      state.formRef.resetFields()
      state.pagination.current = 1
      getTableList()
    }
    function setNearWeek () {
      root.$set(state.params, 'dataRange', [moment(new Date()).subtract(7, 'days'), moment(new Date())])
    }
    function setNearMonth () {
      root.$set(state.params, 'dataRange', [moment(new Date()).subtract(1, 'month'), moment(new Date())])
    }
    return {
      columns,
      ...toRefs(state),
      handleTableChange,
      handleSearch,
      handleReset,
      setNearWeek,
      setNearMonth,
      getTableList
    }
  }
}
</script>
